export default {
    words: {
        or: "oder",
        confirm: "Bestätigen"
    },
    actions: {
        close: "Schließen",
        save: "Speichern",
        check: "Überprüfen",
        yes: "Ja",
        no: "Nein",
        validate: "Validieren",
        back: "Zurück",
        logout: "Abmelden",
        finish: "Beenden",
        continue: "Weiter"
    },
    menu: {
        tickets: "Tickets",
        signings: "Signierstunden",
        planning: "Programm",
        infos: "Infos",
        scan: "Scannen",
        activities: "Aktivitäten",
        queues: "Warteschlangen",
        help: "Hilfe",
        go_to_staff_app: "Mitarbeiter-App",
        go_to_visitor_app: "Besucher-App"
    },
    tools: {
        title: "Werkzeuge",
        version: "Version: {version}",
        reset: "Anwendung zurücksetzen"
    },
    tickets: {
        none_registered: "Sie haben kein registriertes Ticket",
        add: "Ticket hinzufügen",
        scan: "Mein Ticket scannen",
        number: "Ticketnummer",
        visitorName: "Name des Besuchers",
        verify: "Überprüfen",
        validation_impossible: "Das Ticket konnte nicht validiert werden.",
        scan_successful: "Das Ticket <i>{barcode}</i> wurde erfolgreich gescannt.<br>Sie können es benennen, um es leichter zu erkennen.",
        confirm_delete: "Möchten Sie das Ticket '{name}' wirklich löschen?",
        validity: {
            not_yet: "Das Ticket ist noch nicht gültig.",
            expired: "Das Ticket ist nicht mehr gültig.",
            canceled: "Das Ticket wurde storniert",
            the: "Gültig am <b>{date}</b>",
            from_to: "Gültig von <b>{startDate}</b> bis <b>{endDate}</b>"
        },
        errors: {
            not_scanned: "Das Ticket wurde am Eingang noch nicht gescannt",
            invalid: "Das Ticket ist ungültig",
            invalid_today: "Das Ticket ist heute nicht gültig",
            none_linked_to_qr: "Kein Ticket mit diesem QR-Code verknüpft",
            non_existent: "Das Ticket existiert nicht"
        },
        status: {
            valid: "Gültig",
            not_scanned: "Nicht gescannt",
            refunded: "Erstattet",
            canceled: "Storniert",
            pending: "Ausstehend",
            unknown: "Unbekannter Status"
        }
    },
    events: {
        none_for_category: "Kein Event für diese Kategorie geplant"
    },
    bookings: {
        your_number: "Ihre Nummer:",
        called: "Aufgerufen",
        book: "Buchen",
        confirm_cancel: "Möchten Sie diese Reservierung wirklich stornieren?",
        enabled_from: "Reservierungen sind ab {date} möglich",
        confirm_close: "Möchten Sie die Reservierungen wirklich schließen? Die Aktivität wird für Besucher nicht mehr verfügbar sein",
        staff_book_activity_with_ticket: "Aktivität mit Ticket: Geben Sie dem Besucher die Nummer an",
        choose_tickets: "Wählen Sie Tickets zur Reservierung",
        confirmed_title: "Reservierung bestätigt",
        confirmed_info: "Bitte erscheinen Sie spätestens 15 Minuten vorher",
        cancelled: "Reservierung storniert",
        finish: "Finalisieren",
        scanning: {
            start: "Scanning starten",
            end: "Scanning beenden",
            call_next: "Den nächsten {next} aufrufen",
            choose_room_to_start_checkin: "Wählen Sie oben Ihren Raum, um mit der Validierung zu beginnen",
            created_automatically: "Reservierung automatisch erstellt"
        },
        stats: {
            title: "Reservierungsstatistik",
            capaacity: "Kapazität",
            bookings: "Reservierungen",
            scanned: "Gescannt"
        },
        errors: {
            no_booking_for_open: "Keine Buchung für einen offenen Scan-Raum",
            already_scanned: "Ticket bereits für diese Aktivität gescannt",
            no_valid_booking: "Keine gültige Reservierung für dieses Ticket",
            scan_disallowed: "Sie dürfen kein Ticket validieren",
            unknown_activity: "Unbekannte Aktivität",
            activity_full: "Aktivität ausgebucht",
            activity_ended: "Aktivität beendet",
            no_ticket: "Sie haben kein Ticket, das für eine Reservierung berechtigt ist"
        }
    },
    queues: {
        errors: {
            edit_not_allowed: "Sie sind nicht berechtigt, die Warteschlangen zu bearbeiten"
        }
    },
    onboarding: {
        welcome: "Willkommen bei Polymanga {year}!",
        text: "Sie können Ihren Platz für die gewünschten Signierstunden reservieren und so lange Warteschlangen vermeiden! Sobald Ihr Platz gesichert ist, genießen Sie das Festival und erscheinen Sie einige Minuten vor Beginn, um eingelassen zu werden. Scannen, buchen, genießen!",
        to_planning: "Zum Programm gehen"
    },
    permissions: {
        no_camera: "Keine Kamera gefunden oder Berechtigung verweigert. Sie können die Ticketnummer unten eingeben."
    },
    errors: {
        missing_internet: "Sie sind nicht mit dem Internet verbunden. Die angezeigten Informationen sind nicht aktuell!",
        login_impossible: "Anmeldung nicht möglich"
    },
    count_down: {
        bookable_in: "Buchbar in {remaining}"
    },
    pull_refresh: {
        updating: "Aktualisieren...",
        pull: "Ziehen, um zu aktualisieren",
        release: "Loslassen, um zu aktualisieren"
    },
    faq: {
        title: "Hilfe",
        info_stands: {
            title: "Informationsstände",
            content: "Informationsstände befinden sich am Eingang der XXL-Halle und der Westhalle"
        },
        reservations: {
            title: "Reservierungen",
            content: "Aktivitäten mit dem Symbol {ticket} erfordern eine Reservierung. Andere Aktivitäten sind ohne Reservierung zugänglich."
        },
        children: {
            title: "Kinder unter 9 Jahren",
            content: "Gehen Sie zu einem Infostand, um einen QR-Code zu erhalten."
        },
        qr_scan: {
            title: "QR-Code kann nicht gescannt werden",
            content: "Sie können die Nummer auf dem Ticket im Feld \"Ticketnummer\" eingeben."
        },
        multiple_tickets: {
            title: "Mehrere Tickets verwenden",
            content: "Im Tab \"Tickets\" verwenden Sie {plus} und scannen den QR-Code oder geben die Nummer ein."
        },
        cancel_reservation: {
            title: "Reservierung stornieren",
            content: "Im Tab \"Tickets\" klicken Sie auf das {trash}-Symbol der Reservierung."
        },
        ticket_add: {
            title: "Ticket kann nicht hinzugefügt werden",
            content: "Gehen Sie zu einem Infostand."
        },
        qr_small: {
            title: "QR-Code zu klein",
            content: "Sie können die Nummer auf dem Ticket im Feld \"Ticketnummer\" eingeben."
        },
        no_print: {
            title: "Nicht gedrucktes Ticket",
            content: "Sie können die Nummer auf dem Ticket im Feld \"Ticketnummer\" eingeben."
        },
        favorite: {
            title: "Favorit",
            content: "Sie können eine Aktivität speichern, indem Sie auf {star} klicken."
        }
    },
    faq_free: {
        qr_code: {
            title: "Wo befindet sich der QR-Code?",
            content: "Der QR-Code befindet sich oben rechts auf dem Ticket und ist im Bild orange umrandet."
        },
        number: {
            title: "Wo befindet sich die Ticketnummer?",
            content: "Die Ticketnummer befindet sich oben rechts auf dem Ticket, unter dem QR-Code. Sie ist blau umrandet."
        },
        no_answer: {
            title: "Keine Antwort?",
            content: "Gehen Sie zu einem Infostand, wir helfen Ihnen gerne weiter."
        }
    }
}
