export default {
    words: {
        or: "or",
            confirm: "Confirm"
    },
    actions: {
        close: "Close",
            save: "Save",
            check: "Check",
            yes: "Yes",
            no: "No",
            validate: "Validate",
            back: "Back",
            logout: "Logout",
            finish: "Finish",
        continue: "Continue"
    },
    menu: {
        tickets: "Tickets",
            signings: "Signings",
            planning: "Schedule",
            infos: "Info",
            scan: "Scan",
            activities: "Activities",
            queues: "Queues",
            help: "Help",
            go_to_staff_app: "Staff App",
            go_to_visitor_app: "Visitor App"
    },
    tools: {
        title: "Tools",
            version: "Version: {version}",
            reset: "Reset application"
    },
    tickets: {
        none_registered: "You have no registered ticket",
        add: "Add a ticket",
        scan: "Scan my ticket",
        number: "Ticket number",
        visitorName: "Visitor name",
        verify: "Verify",
        validation_impossible: "Unable to validate the ticket.",
        scan_successful: "The ticket <i>{barcode}</i> was scanned successfully.<br>You can name it to easily recognize it.",
        confirm_delete: "Do you really want to delete the ticket '{name}'",
        finish: "Finalize",
        validity: {
            not_yet: "The ticket is not valid yet.",
            expired: "The ticket is no longer valid.",
            canceled: "The ticket is canceled",
            the: "Valid on <b>{date}</b>",
            from_to: "Valid from <b>{startDate}</b> to <b>{endDate}</b>"
        },
        errors: {
            not_scanned: "The ticket has not been scanned at the entrance yet",
                invalid: "The ticket is not valid",
                invalid_today: "The ticket is not valid today",
                none_linked_to_qr: "No ticket linked to this QR Code",
                non_existent: "The ticket does not exist"
        },
        status: {
            valid: "Valid",
                not_scanned: "Not scanned",
                refunded: "Refunded",
                canceled: "Canceled",
                pending: "Pending",
                unknown: "Unknown status"
        }
    },
    events: {
        none_for_category: "No event scheduled for this category"
    },
    bookings: {
        your_number: "Your number:",
            called: "Called",
            book: "Book",
            confirm_cancel: "Do you really want to cancel this reservation?",
            enabled_from: "Reservations will be available starting from {date}",
            confirm_close: "Do you really want to close the reservations? The activity will no longer be available to visitors",
            staff_book_activity_with_ticket: "Activity with ticket: provide the number to the visitor",
            choose_tickets: "Choose tickets to book",
            confirmed_title: "Reservation confirmed",
            confirmed_info: "Please arrive no more than 15 minutes before",
            cancelled: "Reservation canceled",
            scanning: {
            start: "Start scanning",
                end: "End scanning",
                call_next: "Call the next {next}",
                choose_room_to_start_checkin: "Choose your room above to start validation",
                created_automatically: "Reservation created automatically"
        },
        stats: {
            title: "Reservation Statistics",
                capaacity: "Capacity",
                bookings: "Reservations",
                scanned: "Scanned"
        },
        errors: {
            no_booking_for_open: "No booking for a room with open scanning",
                already_scanned: "Ticket already scanned for this activity",
                no_valid_booking: "No valid reservation for this ticket",
                scan_disallowed: "You are not allowed to validate a ticket",
                unknown_activity: "Unknown activity",
                activity_full: "Activity full",
                activity_ended: "Activity ended",
                no_ticket: "You have no ticket eligible for a reservation"
        }
    },
    queues: {
        errors: {
            edit_not_allowed: "You are not authorized to edit the queues"
        }
    },
    onboarding: {
        welcome: "Welcome to Polymanga {year}!",
            text: "You can reserve your spot for the signings of your choice and avoid waiting in line! Once your spot is secured, enjoy the festival and arrive a few minutes before the start to enter. Scan, book, enjoy!",
            to_planning: "Go to schedule"
    },
    permissions: {
        no_camera: "No camera was found or permission was denied. You can enter the ticket number below."
    },
    errors: {
        missing_internet: "You are not connected to the internet. The displayed information is not up to date!",
            login_impossible: "Login impossible"
    },
    count_down: {
        bookable_in: "Bookable in {remaining}"
    },
    pull_refresh: {
        updating: "Updating...",
            pull: "Pull to refresh",
            release: "Release to refresh"
    },
    faq: {
        title: "Help",
        info_stands: {
            title: "Information stands",
            content: "Information stands are located at the entrance of the XXL hall and the West hall"
        },
        reservations: {
            title: "Reservations",
            content: "Activities with the {ticket} require a reservation. Other activities are accessible without reservation."
        },
        children: {
            title: "Children under 9",
            content: "Go to an info stand to receive a QR code."
        },
        qr_scan: {
            title: "Unable to scan QR Code",
            content: "You can enter the number on the ticket in the \"Ticket number\" field."
        },
        multiple_tickets: {
            title: "Use multiple tickets",
            content: "In the Tickets tab, use the {plus} then scan the QR Code or enter the number."
        },
        cancel_reservation: {
            title: "Cancel a reservation",
            content: "In the Tickets tab, click on the {trash} of the reservation."
        },
        ticket_add: {
            title: "Cannot add ticket",
            content: "Go to an info stand."
        },
        qr_small: {
            title: "QR Code too small",
            content: "You can enter the number on the ticket in the \"Ticket number\" field."
        },
        no_print: {
            title: "Unprinted ticket",
            content: "You can enter the number on the ticket in the \"Ticket number\" field."
        },
        favorite: {
            title: "Favorite",
            content: "You can save an activity by clicking on the {star}"
        },
    },
    faq_free: {
        qr_code: {
            title: "Where is the QR code?",
            content: "The QR code is located at the top right of the ticket, highlighted in orange in the image."
        },
        number: {
            title: "Where is the ticket number?",
            content: "The ticket number is located at the top right of the ticket, below the QR code. It is highlighted in blue."
        },
        no_answer: {
            title: "No answer?",
            content: "Go to an info stand, we will be happy to find a solution."
        }
    }
}