<template>
  <div class="page">
    <div class="logo">
      <img src="@/assets/logo.svg">
    </div>
    <div class="scrollable">
      <div class="pannel-lang">
        <select class="select-lang" v-model="$i18n.locale" @change="langSelected">
          <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
            {{ langs[locale] ?? locale }}
          </option>
        </select>
      </div>
      <div class="pannel" v-html="html" v-if="html.trim() !== ''">
      </div>
      <div class="pannel" v-if="isStaff">
        <h2>Staff</h2>
        <button @click="$router.push('/staff')">{{ $t('menu.go_to_staff_app') }}</button>
      </div>
      <div class="pannel faq">
        <h2>{{ $t('faq.title') }}</h2>
        <template v-for="(item, key) in faqItems" :key="key">
          <collapsable :title="$t(`faq.${item}.title`)">
            <i18n-t :keypath="`faq.${item}.content`" tag="span">
              <template #ticket>
                <Ticket class="inline-icon" />
              </template>
              <template #plus>
                <Plus class="inline-icon" />
              </template>
              <template #trash>
                <TrashCanOutline class="inline-icon" />
              </template>
              <template #star>
                <StarOutline class="inline-icon" />
              </template>
            </i18n-t>
          </collapsable>
        </template>
        <collapsable :title="$t(`faq_free.qr_code.title`)">
          {{ $t(`faq_free.qr_code.content`) }}
          <img src="@/assets/help/qrcode.png"  alt="Position du qrcode sur le billet">
        </collapsable>
        <collapsable :title="$t(`faq_free.number.title`)">
          {{ $t(`faq_free.number.content`) }}
          <img src="@/assets/help/code.png"  alt="Position du numéro sur le billet">
        </collapsable>
        <collapsable :title="$t(`faq_free.no_answer.title`)">
          {{ $t(`faq_free.no_answer.content`) }}
        </collapsable>
      </div>
      <div class="pannel">
        <h2>Charte POLYMANGA</h2>
        <p>
          POLYMANGA est un espace de partage et de découverte ouvert à tou·x·t·e·s. Pour que tout le monde puisse
          profiter un maximum de cet espace et de ce qu'il propose, il nécessite l'attention et le respect de
          chacun·e·x.
        </p>
        <p>
          En profitant de la convention, je m'engage à adopter un comportement responsable et respectueux envers
          l'espace, les personnes et le matériel qui font la convention. Cela veut dire&nbsp;:
        </p>
        <ol>
          <li>
            <p><b>Je respecte, et mérite le respect de, tous les participants du POLYMANGA&nbsp;:</b></p>
            <p>
              Visiteurs, cosplayeurs, exposants, animateurs, invités, staff, employés (équipe de nettoyage, sécurité,
              agents VIP), je traite chacun·e·x avec politesse et ouverture.
            </p>
          </li>
          <li>
            <b>Je ne réalise pas de paroles, gestes, comportements</b> sexistes, homophobes, transphobes, racistes,
            validistes, agistes, islamophobes, antisémites, ni quelconque autre forme de <b>discrimination et de
            stigmatisation</b>, portant atteinte à la personne, ce qu'elle porte, ce qu'elle exprime, ce qu'elle
            représente, ou ce qui en est présumé.
          </li>
          <li>
            <p><b>Je respecte l'espace personnel et le corps de chacun·e·x</b></p>
            <p>
              Je n'aurai pas de propos ou gestes intimidants, harcelants, malveillants. Je n'aurai pas de propos ou de
              geste rapprochés et intimes avec une personne qui ne m'a pas donné son consentement (contacts,
              rapprochements, attouchements, frottements, taquineries, blagues, insinuations, photos…)
            </p>
          </li>
          <li>
            <p>En plus de couvrir mon postérieur et mes génitaux, <b>je veille à porter un vêtement sur mon buste, même
              cosplayé.</b></p>
          </li>
          <li>
            <p><b>Je ne prends pas en photos une personne, </b>
              ou un groupe de personnes (visiteurs, cosplayeurs, exposants, animateurs, invités, VIP, staff, personnel)
              sans demander l'accord au préalable&nbsp;!
            </p>
          </li>
          <li>
            <p>
              <b>Je réflechis avant de demander les contacts </b>
              de quelqu'un si c'est approprié et pertinent. Si la personne ne veut pas, j'accepte sa réponse et je la
              laisse tranquille.
              Je fais attention avant de donner mes données personnelles (prénom, nom, âge, adresse…) et mes contacts
              (numéro de téléphone, profil réseaux sociaux,…) à une personne que je ne connais pas.
            </p>
          </li>
          <li>
            <p>
              <b>Je respecte les espaces, biens et matériels</b>
              mis à ma disposition ou à ma portée durant toute la convention (tables, stands, marchandises, consoles de
              jeux, sièges, pancartes, toilettes, etc).
            </p>
          </li>
          <li>
            <p><b>Nous sommes tou·x·t·e·s responsable et pouvons tou·x·t·e·s faire la différence. </b>
              L'espace de la convention se veut et s'espère suffisamment sécuritaire et confortable pour tou·x·t·e·s.
            </p>
          </li>
        </ol>
        <p>
          Si je suis témoin d'une situation désagréable, violente, discriminante, ou encore si je remarque du matériel
          manquant, abimé ou un espace dangereux (ex. sol glissant, fissuré, chaise cassée, accès compliqué ou
          impossible), je peux le signaler au staff. Celui-ci le fera remonter plus haut dans l'organisation, pour que
          la situation change.
        </p>
      </div>
      <div class="pannel">
        <h2>Cosplay is not consent!</h2>
        <p>
          Lae cosplayeur (et autre personne costumée) ne te doit rien. Hormis le même respect que tu lui donneras.
        </p>
        <ul>
          <li>Réfléchis avant de donner un compliment ou un conseil non sollicité.</li>
          <li>Réfléchis avant de lui demander ses contacts. Et si tu les demandes et que la personne ne veut pas,
            accepte sa réponse et laisse-la tranquille.
          </li>
          <li>N'oublie pas que c'est une vraie personne derrière son costume. Ne touche pas la personne. Respecte son
            espace personnel.
          </li>
          <li>Demande-lui son accord avant de prendre une photo.</li>
        </ul>
      </div>
      <div class="pannel">
        <h2>{{ $t('tools.title') }}</h2>
        <p>{{ $t('tools.version', { version: version }) }}</p>
        <p class="update" @click="update"><a href="#none">{{ $t('tools.reset') }}</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import {PersistentStore} from "@/services/PersistentStore";
import Collapsable from "../Common/Collapsable";
import {TrashCanOutline, Plus, Ticket, StarOutline} from "mdue"

export default {
  name: "Infos",
  components: {
    Collapsable,
    TrashCanOutline,
    Plus,
    Ticket,
    StarOutline,
  },
  data() {
    return {
      isStaff: false,
      html: "",
      version: PersistentStore.version,
      langs: {
        fr: "Français",
        en: "English",
        de: "German",
      },
      faqItems: [
        'info_stands',
        'reservations',
        'children',
        'qr_scan',
        'multiple_tickets',
        'cancel_reservation',
        'ticket_add',
        'qr_small',
        'no_print',
        'favorite'
      ]
    }
  },
  mounted() {
    this.isStaff = PersistentStore.isStaffLoggedIn;
    this.$api.infos.public().then((html) => this.html = html);
  },
  methods: {
    update() {
      location.reload(true)
      this.$api.clear()
    },
    langSelected(v) {
      localStorage.setItem('lang', v.target.value)
    }
  }
}
</script>

<style scoped >
.pannel {
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

}

.pannel > *:first-child{
  margin-top: 0;
}

.pannel-lang{
  padding: 20px 20px 0 20px;
}

.pannel-lang select{
  background-color: #ffffff;
  border: none;
  font-weight: bold;
  padding: 7px 17px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 7px;
  width: 100%;
}

ul, ol {
  padding-left:23px;
}

.pannel:last-child {
  margin-bottom: 80px;
}

.pannel h2, .pannel h1 {
  margin-top: 0;
  margin-bottom: 10px;
}

.logo {
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  background: linear-gradient(47deg, #6f00aa 0%, #9f159d 100%);
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  height: auto;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 20px);
  filter: invert(100%);
}


.page {
  display: grid;
  grid-template-rows: 70px 1fr;
  height: 100%;
}

.scrollable {
  height: 100%;
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 0px !important;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7542d5;
}

.update {

}

.faq > * {
  margin-top: 10px;
}

.inline-icon {
  vertical-align: text-bottom;
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
}

</style>
