import { DateTimeZone } from "./DateTimeZone"

export class Show {
    id
    salle
    name
    start
    end
    delay
    capacity
    col
    order
    isFull
    isCancel
    isFlush
    bookings
    scanned
    level
    scanning
    bookingType
    isEnded
    description
    webArticleUrl

    static fromJson(json){
        if(!json)
            return null

        const show = new Show();

        show.id = json.id;
        show.salle = json.salle;
        show.name = json.name;
        show.start = DateTimeZone.fromJson(json.start);
        show.end = DateTimeZone.fromJson(json.end);
        show.delay = json.delay;
        show.capacity = json.capacity;
        show.col = json.col;
        show.order = json.order;
        show.isFull = json.is_full;
        show.isCancel = json.is_cancel;
        show.isFlush = json.is_flush;
        show.bookings = json.bookings;
        show.scanned = json.scanned;
        show.level = json.level;
        show.scanning = json.scanning;
        show.isEnded = json.is_ended;
        show.description = json.description;
        show.bookingType = json.booking_type;
        show.webArticleUrl = json.web_article_url;

        return show;
    }
}