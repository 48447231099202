<template>
  <div class="page shadow" v-if="show">
    <h2>{{ show.name }}</h2>
    <p>{{ show.description }}</p>


    <button @click="bookForVisitor" v-if="show.bookingType === 1 || show.bookingType === 2">
      Réserver pour un visiteur
    </button>
    <template v-if="canAdmin">
      <button @click="startScanning" v-if="!show.scanning">{{ $t('bookings.scanning.start') }}</button>
      <button @click="endScanning" v-if="show.scanning">{{ $t('bookings.scanning.end') }}</button>
      <button @click="nextTicket" v-if="show.bookingType === 2">{{ $t('bookings.scanning.call_next', { next: next }) }}</button>
    </template>

    <confirm ref="confirm" @validate="closeBookings" :title="$t('words.confirm')">
      {{ $t('bookings.confirm_close') }}
    </confirm>

    <button class="outline" @click="$router.push('/staff/planning')">{{ $t('actions.back') }}</button>

  </div>
  <div class="page shadow" v-if="show">
    <h3>{{ $t('bookings.stats.title') }}</h3>
    <table>
      <thead>
      <tr>
        <th>{{ $t('bookings.stats.capacity') }}</th>
        <th>{{ $t('bookings.stats.bookings') }}</th>
        <th>{{ $t('bookings.stats.scanned') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{ show.capacity }}</td>
        <td>{{ show.bookings }} ({{ Math.round(show.bookings / show.capacity * 100) }}%)</td>
        <td>{{ show.scanned }} ({{ Math.round(show.scanned / show.capacity * 100) }}%)</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import Confirm from "@/components/Common/Dialog/Confirm";
import {PersistentStore} from "../../../services/PersistentStore";

export default {
  name: "ActivityDetail",
  components: {
    Confirm
  },
  data() {
    return {
      show: undefined,
      showId: undefined,
      canAdmin: false,
      next: "",
    }
  },
  async mounted() {
    this.showId = this.$route.params.id;
    this.updateShow()
  },
  methods: {
    async closeBookings() {
      await this.$api.shows.close(this.showId)
      await this.updateShow();
    },
    bookForVisitor() {
      this.$router.push(`/staff/planning/${this.$route.params.id}/book`)
    },
    async startScanning() {
      await this.$api.shows.startScanning(this.showId)
      await this.updateShow();
    },
    async endScanning() {
      await this.$api.shows.endScanning(this.showId)
      await this.updateShow();
    },
    async updateShow() {
      this.show = await this.$api.shows.byId(this.showId)
      this.canAdmin = PersistentStore.allowedSalles.includes(this.show.salle);
      this.next = (await this.$api.shows.last_called(this.showId))?.data?.number ?? "";
    },
    async nextTicket() {
      const result = await this.$api.shows.next(this.showId);
      if (result.status !== 200) {
        this.next = "";
      } else {
        this.next = (await this.$api.shows.last_called(this.showId))?.data?.number ?? "";
      }
    }
  }
}
</script>

<style scoped>
h2, h3 {
  margin: 0 0 20px 0;
}

.page {
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 6px;
}

.outline {
  color: #7643D6;
  border-color: #7643D6;
}

.delete {
  background-color: #c3524f;
}

table {
  width: 100%;
  text-align: left;
}

table .first {
  padding: 15px 0px;
}
</style>