<template>
  <div class="backdrop" v-if="visible" >
    <div class="modal">
      <h3>{{ $t('words.confirm') }}</h3>
      <div class="content"><slot></slot></div>
      <div class="actions">
        <button class="small outline base" @click="cancel">{{ $t('actions.no') }}</button>
        <button class="small" @click="ok">{{ $t('actions.yes') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  emits: ['validate', 'hide'],
  data() {
    return {
      visible: false,
      context: undefined
    }
  },
  methods: {
    show(context){
      this.visible = true
      this.context = context
    },
    cancel(){
      this.hide()
    },
    ok() {
      this.hide()
      this.$emit("validate", this.context)
    },
    hide() {
      this.visible = false
      this.$emit("hide", this.context)
    },

  }
}
</script>

<style scoped>
  .backdrop{
    z-index: 10000;
    position: fixed;
    background-color: rgba(30, 30, 30, 0.91);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal{
    width: min(100%, 300px);
    background-color: white;
    padding: 20px;
    border-radius: 6px;
  }

  .modal h3{
    margin: 0;
  }

  .actions{
    display: flex;
    column-gap: 10px;
    justify-content: right;
  }

  .actions button{
    min-width: min-content;
    padding-left: 20px;
    padding-right: 20px;
  }
</style>